<template>
  <div class="boardsearch">
    <ui-select :items="itemList" @input="getSelectValue" :type="'board'" :value="filterValue"/>
    <input type="text" :placeholder="$t('front.search.emptySearch')" v-model="filterText"/>
    <a @click="onSearch">
      <img src="@/assets/img/search.svg" alt=""/>
    </a>
  </div>
</template>

<script>
import UiSelect from '@/components/ui/UiSelect'

export default {
  name: 'BoardFilter',
  components: {
    UiSelect
  },
  props: [
    'className'
  ],
  data () {
    return {
      filterValue: '',
      filterText: '',
      itemList: [
        { name: 'front.search.title', value: 't', default: true },
        { name: 'front.search.content', value: 'c' },
        { name: 'front.search.titleContent', value: 'tc' }
      ]
    }
  },
  computed: {
    canSubmit () {
      return this.filterText
    }
  },
  created () {
    const list = this.itemList
    for (let i = 0, iLen = list.length; i < iLen; i++) {
      const item = list[i]
      if (item.default) {
        this.filterValue = item.value
        break
      }
    }
  },
  methods: {
    getSelectValue (value) {
      this.filterValue = value
      this.$emit('input', value)
    },
    onSearch () {
      if (!this.canSubmit) {
        this.onCheck('front.search.emptySearch')
        return
      }

      const params = {
        type: this.filterValue,
        text: this.filterText
      }
      this.$emit('onSearch', params)
    }
  }
}
</script>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
